import './App.css';
// import xGamma from "../src/assets/mainXgamma.png"
import TextTransition, {presets} from "react-text-transition";
import React from "react";

function App() {
    const TEXTS = ['Open Source.', '100% free to use.', 'Extreme reliability.', "No more crashes.", "Superficial load speeds."];
    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            2000,
        );
        return () => clearTimeout(intervalId);
    }, []);
    return (
    <div className="App">
      <header className="App-header">
      </header>
      <div style={{
            position:"absolute",
            top: 0,
            left: 0,
            width:"100vw",
            height:"100vh",
            display:"flex",
            flexDirection:"column"
        }}>
            <div style={{
                backgroundColor:"rgba(75,75,75,.76)",
                width:"100vw",
                color:"#ffffff",
                display:"flex",
                gap:"30px",
                alignItems:'center',
                height:"40px",
                justifyContent:"center",
                fontSize:"12px"
            }}>
                <p>Applications</p>
                <p>GitHub</p>
                <p>PlayStore</p>
                <p>Development</p>
                <p>Support</p>
            </div>
              <div style={{
                  flex:1,
                  display:"flex",
                  alignItems:'center',
                  justifyContent:"center",
                  width:"100%"
              }}>
                  <p style={{
                      color: "#000000",
                      fontSize: "4rem",
                      fontWeight: 600,
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      backgroundColor: "#ffffff",
                      padding: "20px"
                  }}>
                      <TextTransition
                          inline={false}
                          springConfig={presets.stiff}
                      >
                          {TEXTS[index % TEXTS.length]}
                      </TextTransition>
                  </p>
              </div>
      </div>
    </div>
  );
}

export default App;
